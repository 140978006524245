<template>
  <div class="record-invoice">
    <div class="m-title">开票记录</div>
    <el-card v-loading="loading">
      <el-table :data="recordList" style="width: 100%" 
        :header-cell-style="{ 'text-align': 'center', color: '#aaa' }"
        :cell-style="{ 'text-align': 'center', color: '#000' }">
        <el-table-column prop="id" label="ID" width="60">
        </el-table-column>
        <el-table-column  label="公司名称">
        {{ company_name }}    
        </el-table-column>
        <el-table-column prop="invoice_type" label="发票类型">
          <template slot-scope="scope">
            {{ scope.row.invoice_type | typeStatus }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag :type="
                  scope.row.status == '0'
                    ? ''
                    : scope.row.status == '1'
                    ? 'success':scope.row.status == '2'?'danger':'info'
                ">
              {{ scope.row.status | orderStatus }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="orderAmount" label="申请金额">
            <template slot-scope="scope">
                <div style="color: #ff7125">{{ scope.row.orderAmount}}</div>
            </template>
        </el-table-column>
        <el-table-column prop="addtime" label="时间"> </el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="lookInvoiceDetails(scope.row.id)">查看</el-button>
            <el-button size="mini" v-if=" scope.row.status == '0'" type="primary"
              @click="cancleInvoiceApply(scope.row.id)">取消</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-row class="pagination">
        <Pagination :page.sync="page" :limit.sync="limit" :total="recordCount" @getList="getRecordList" />
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import {
    getRecordListApi,
    cancleInvoiceApplyApi,
    getInvoiceInfoApi
  } from '@/api/user'
  import Pagination from '@/components/Pagination/index.vue'
  export default {
    name: 'recordInvoice',
    components: {
      Pagination
    },
    data() {
      return {
        recordList: [],
        company_name:'',
        page:1,
        limit:10,
        recordCount:0,
        loading:false
      }
    },
    filters: {
      orderStatus(val) {
        let msg = ""
        switch (val) {
          case 0:
            msg = "未开票";
            break;
          case 1:
            msg = "已开票";
            break;
          case 2:
            msg = "驳回";
            break;
          case 3:
            msg = "已取消";
            break;
        }
        return msg
      },
      typeStatus(val) {
        let msg = ""
        switch (val) {
          case 0:
            msg = "普通发票";
            break;
          case 1:
            msg = "增值税专用发票";
            break;
        }
        return msg
      }
    },
    created() {
      this.getRecordList()
      this.getInvoiceInfo()
    },
    methods: {
        //发票信息初始化
      async getInvoiceInfo() {
        this.loading=true
        const {
          data: res
        } = await getInvoiceInfoApi()
        if (res.status !== 10000) {
          return this.$message.error(res.message)
        }
          this.company_name = res.result.c_name
          this.loading=false
      },
        //开票记录列表
      async getRecordList() {
        this.loading=true
        const {
          data: res
        } = await getRecordListApi(this.page,this.limit)
        if (res.status != 10000) {
          return this.$message.error(res.message)
        }
        this.recordList = res.result.data
        this.recordCount=res.result.count
        this.loading=false
      },
      cancleInvoiceApply(id) {
        this.$confirm('此操作将取消发票申请, 是否继续?', '取消发票申请确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          cancleInvoiceApplyApi(id).then(res => {
            this.$message.success('取消发票申请成功')
            this.getRecordList()
          })
        }).catch(() => {
          this.$message.info('已取消操作');
        });
      },
      lookInvoiceDetails(id){
        this.$router.push(`/pc/invoice/record/details?id=${id}`)
      }
    },
  }

</script>

<style lang="less" scoped>
  .m-title {
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    border-bottom: 1px solid #e4e7ed;
    text-align: center;
  }
  .pagination {
    margin-top: 20px;
    text-align: right;
  }

</style>
